import React, { useState } from "react"
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "@emotion/styled"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { motion } from "framer-motion"
import useOnclickOutside from "react-cool-onclickoutside"
import { CaretLeft, CaretRight } from "phosphor-react"
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { useMediaQuery } from 'react-responsive'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Title from '../components/title'

const Titling = styled.div`
position: absolute;
bottom: 0;
left: 50%;
transform: translateX(-50%);
height: 12.5vh;
@media (max-width: 599px) {
  text-align: center;
  bottom: 8svh;
  height: auto;
  transform: none;
  left: inherit;
}
h1, h3 {
    display: inline-block;
    position: relative;
    top: 45%;
    transform: translateY(-50%);
    line-height: 1rem;
    @media (max-width: 599px) {
      display: block;
      top: inherit;
      width: 90vw;
      line-height: inherit;
    }
}
h1 {
  margin-right: 9px;
}
h3 {
    font-size: .8125rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 500;
    color: rgba(0,0,0,0.6);
}
`
const CarouselWrap = styled.div`
  height: 77.5vh;
  position: relative;
  &[data-platform='iPad'] {
    height: 67.5vh;
  }
  @media (max-width: 599px) {
    height: 80vh;
  }
  .carousel .slide img {
    pointer-events: all;
  }
  img {
    max-height: 77.5vh;
    max-width: 75vw;
    width: auto !important;
    height: auto !important;
    box-sizing: border-box !important;
    padding: 24px !important;
    background-color: white !important;
    &[data-platform='iPad'] {
      max-height: 67.5vh;
    }
    @media (max-width: 599px) {
      max-width: 92vw;
      max-height: 60svh;
      padding: 0 !important;
      background-color: transparent !important;
    }
  }
  .carousel.carousel-slider {
      margin: 0 auto;
      @media (max-width: 599px) {
        width: 92vw !important;
        position: relative;
      }
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }
  .carousel .slider {
    height: 77.5vh;
    &[data-platform='iPad'] {
      height: 67.5vh;
    }  
    @media (max-width: 599px) {
      height: 80svh;
    }
  }
  .carousel .slide > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  button {
    background: rgba(255,255,255,0.4);
    border: 0;
    padding: 0;
    color: rgba(0,0,0,.2);
    position: absolute;
    z-index: 5;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        color: rgba(0,0,0,1);
        background: rgba(255,255,255,.8);
    }
    &.next {
      right: 0;
    }
}
`
const CarouselNav = styled.div`

`
const Drawer = styled.section`
pointer-events: all;
.simplebar-scrollbar::before {
  background-color: rgba(0,0,0,0.33);
}
&[data-active='true'] {
  background-color: rgba(255,255,255,.6);
}
nav {
  overflow: hidden;
}
button {
    background: transparent;
    color: rgba(0,0,0,0.25);
    border: 0;
    font-size: .8125rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left;
    height: 12.5vh;
    padding: .2vw 0;
    z-index: 10;
    position: absolute;
    bottom: 0;
    right: 4vw;
    transition: color 0.3s ease, background-color 0.3s ease;
    &:hover {
        cursor: pointer;
        color: rgba(0,0,0,0.75);
    }
    &[data-ison='true'] {
      position: fixed;
      color: rgb(0,0,0);
      @media (max-width: 599px) {
        background-color: #fff;
      }
    }
    &::after {
      content: ' More';
    }
    &[data-ison='true']::after {
      content: ' Less';
    } 
    @media (max-width: 599px) {
        right: inherit;
        left: 50%;
        height: 10vh;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
    }
}
> div {
    position: fixed;
    z-index: 7;
    left: 0;
    bottom: 0;
    background: rgba(255,255,255,0.95);
    width: 100vw;
    height: 66.66vh;
    box-sizing: border-box;
    transition: visibility 0 linear;
    transition-delay: .3s;
    transition-property: visibility;
    > section {
      margin: 10vh 4vw 5vh 5vw;
      display: grid;
      pointer-events: all;
      grid-template-columns: 4fr 3fr;
      grid-column-gap: 10vw;
      overflow: hidden;
      @media (max-width: 599px) {
        display: block;
        overflow-y: scroll;
        height: 65vh;
        > div {
          margin-bottom: 2.5vh;
          &:last-of-type {
            margin-bottom: 10vh;
          }
        }
      }
    }
    @media (max-width: 599px) {
      height: 75vh;
    }
}
p {
    font-weight: 500;
    line-height: 1.75em;
    padding-bottom: 20px;
    letter-spacing: .5px;
}
span {
  font-size: .9125rem;
  letter-spacing: 2px;
  font-weight: 800;
}
p.small {
  color: rgba(0,0,0,0.75);
  font-size: .875rem;
}
dl {
    font-size: .9125rem;
    font-weight: 500;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-auto-rows: min-content;
    vertical-align: baseline;
}
dt, dd { 
    line-height: 1.75rem;
    border-bottom: solid 1px rgba(0,0,0,0.05);
    padding: .5vh 0;
}
dt { 
    letter-spacing: .5px;
    font-size: .75rem;
    color: rgba(0,0,0,0.5);
}
dd {
    font-weight: 800;
}
`
const Related = styled.section`
font-family: "Manrope";
min-width: 92vw;
background: #fff;
min-height: 20vh;
margin-top: 2.5vh;
margin-bottom: 10vh;
padding: 3vh 4vw 0 4vw;
@media (max-width: 599px) {
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.simplebar-scrollbar::before {
  background-color: rgba(0,0,0,0.33);
}
> h6 {
  font-weight: 800;
  margin-bottom: 2.5vh;
  font-size: .9125rem;
  color: rgba(0,0,0,0.7);
}
section {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
section > div {
  margin-right: 6vw;
  flex: 0 0 auto;
}
section > div:last-of-type {
  margin-right: 0;
}
section > div a > div {
  position: relative;
}
.gatsby-image-wrapper {
  left: 50%;
  transform: translateX(-50%);
}
img {
    padding: 0 !important;
    max-height: 12.5vw;
    width: auto;
    @media (max-width: 599px) {
      max-height: 25vw;
    }
}
h3 {
  line-height: 1rem;
  font-size: .8125rem;
  letter-spacing: .75px;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
h6 {
  flex: 100%;
}
a {
  text-decoration: none;
  color: #000;
  transition: all 0.3s ease;
  &:hover h3 {
    opacity: 1;
  }
  &:hover img {
    opacity: .75 !important;
  }
  h3 {
    opacity: 0;
    transition: all 0.3s ease;
    text-align: center;
  }
}
`

const Projects = ({ data }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const handleBtnClick = () => setOpenMenu(!openMenu);
    const closeMenu = () => setOpenMenu(false);
    const ref = useOnclickOutside(() => closeMenu());
    const [currentSlide, setcurrentSlide] = useState(0)

    const next = () => setcurrentSlide(currentSlide + 1)
    const prev = () => setcurrentSlide(currentSlide - 1)
    const total = data.single.projectImages && data.single.projectImages.length

    const isDesktop = useMediaQuery({ query: '(min-width: 600px)' })

    return (
        <>
        <Layout>
        <Seo title={data.single.projectTitle} image={data.single.bannerImage.resize.src}/>
        <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{
                        delay: 0.3,
                        type: "spring",
                        mass: 0.35,
                        stiffness: 75,
                        duration: 0.5
                      }}
        >
          <Titling>
            <Title className="noheight">{data.single.projectTitle}</Title>
            <h3>{data.single.location}</h3>
          </Titling>
          {data.single.projectImages &&
          <CarouselWrap>
            {isDesktop ? 
            <>
            <CarouselNav>
              <button onClick={prev} className="prev">
                <CaretLeft size={48} weight="thin"/>
              </button>
              <button onClick={next} className="next">
                <CaretRight size={48} weight="thin"/>
              </button>
            </CarouselNav>

            <Carousel animationHandler="fade" width={'75vw'} transitionTime={500} showArrows={false} showThumbs={false} showStatus={false} showIndicators={false} autoPlay={false} infiniteLoop={true} swipeable={false} selectedItem={currentSlide <= total ? currentSlide : setcurrentSlide(0)} onChange={(index) => setcurrentSlide(index)}
            >
            {data.single.projectImages.map(({ id, gatsbyImageData }) => (
                <div key={id}><GatsbyImage image={gatsbyImageData} alt="image" data-fancybox="images" objectFit="contain" /></div>
            ))}
            </Carousel>
            </> : 
            <Carousel width={'75vw'} showArrows={false} showThumbs={false} showStatus={false} showIndicators={false} autoPlay={false} infiniteLoop={true} swipeable={true} emulateTouch={true} selectedItem={currentSlide <= total ? currentSlide : setcurrentSlide(0)} onChange={(index) => setcurrentSlide(index)}
            >
            {data.single.projectImages.map(({ id, gatsbyImageData }) => (
                <div key={id}><GatsbyImage image={gatsbyImageData} alt="image" data-fancybox="images" objectFit="contain" /></div>
            ))}
            </Carousel>
            }
        </CarouselWrap >
        }
        <Drawer ref={ref}>
          <button onClick={handleBtnClick} data-ison={openMenu} >
            Read 
            </button>
            {openMenu && (
            <motion.div
            initial={{ opacity: 0, y: "10vh" }}
            animate={{
              y: "0vh",
              opacity: 1
            }}
            transition={{type: "spring", duration: 0.5}}
            >
          <section>
          { data.single.projectDescription && 
          <div>
          {isDesktop ?
          <SimpleBar style={{ maxHeight: "50vh"}}>
          <div>{renderRichText(data.single.projectDescription)}</div>
          </SimpleBar> :
          <div>{renderRichText(data.single.projectDescription)}</div>
          }
          </div>
          }
          <div>
                { data.single.projectTitle && <dl><dt>Project</dt><dd>{data.single.projectTitle}</dd></dl> }
                { data.single.location && <dl><dt>Location</dt>
                <dd>{data.single.location}</dd></dl> }
                { data.single.completed && <dl><dt>Completed</dt><dd>{data.single.completed}</dd></dl> }
                { data.single.expected && <dl><dt>Expected</dt><dd>{data.single.expected}</dd></dl> }
                { data.single.contractor && 
                  <dl>
                    <dt>Contractor</dt>{data.single.contractor.map(item => ( <dd> { item.link ? <a target="_blank" rel="noopener noreferrer" href={item.link}>{item.name}</a> : <span>{item.name}</span> } </dd> ))}
                  </dl>
                }
                { data.single.collaborator && 
                  <dl>
                    <dt>With</dt><dd><ul>{data.single.collaborator.map(item => (<li key={item.id}>{ item.link ? <a target="_blank" rel="noopener noreferrer" href={item.link}>{item.name}</a> : <span>{item.name}</span> }</li>))}</ul></dd>
                  </dl> 
                }
                { data.single.awards && 
                  <dl>
                    <dt>Recognition</dt><dd><ul>{data.single.awards.map(item => (<li key={item.id}>{item.title} {item.year}</li>))}</ul></dd>
                  </dl> 
                }
          </div>
          </section>
          </motion.div>
          )}
        </Drawer>
        </motion.div>
      </Layout>
      <Related>
        <h6>More Projects</h6>
        <SimpleBar style={{ maxWidth: "92vw"}}>
        <section>
        {data.all.referencePages.map(node => (
          <div key={node.id}>{ node.bannerImage &&<Link to={`/project/${node.slug}`}><div><GatsbyImage image={node.bannerImage.gatsbyImageData} alt="image" objectFit="contain" /><h3>{node.projectTitle}</h3></div></Link>}</div>
        ))}
        </section>
        </SimpleBar>
      </Related>
      <Footer />
      </>
    )
}

export const query = graphql`
query ($slug: String) {
  single: contentfulProject(slug: {eq: $slug}) {
    id
    projectTitle
    location
    completed
    expected
    contractor {
      name
      link
      id
    }
    collaborator {
      id
      name
      link
    }
    awards {
      title
      year
      id
    }
    bannerImage {
      resize(aspectRatio: 1.91, width: 1200) {
        width
        height
        src
      }
      gatsbyImageData
      (
        width: 1800
        height: 1800
      )
    }
    slug
    projectDescription {
      raw
    }
    projectImages {
      id
      gatsbyImageData
    }
  }
  all: contentfulPage(title: {eq: "Work"}) {
    referencePages {
      ... on ContentfulProject {
        id
        projectTitle
        slug
        bannerImage {
          gatsbyImageData
        }
      }
    }
  }
}
`

export default Projects